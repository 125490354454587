import type { AuthState } from './AuthState';
import { getRole } from '@/helpers/tokenHelpers';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category Authentification
 */

export default {
  /**
   * Set AuthState loading status.
   * @param state AuthState.
   */

  auth_request(state: AuthState): void {
    state.authStatus = 'loading';
  },

  /**
   * Set AuthState authorized status.
   * @param state AuthState.
   * @param token authorization token.
   */

  auth_success(state: AuthState, token: string): void {
    state.authStatus = 'success';
    state.token = token;
    state.role = getRole(token);
  },

  /**
   * Set AuthState error status.
   * @param state AuthState.
   */
  auth_error(state: AuthState): void {
    state.authStatus = 'error';
  },

  /**
   * Set Token Name.
   * @param state AuthState.
   */
  setTokenSymbol(state: AuthState, tokenSymbol: string): void {
    state.tokenSymbol = tokenSymbol;
  },

  /**
   * Set Operator id.
   * @param state AuthState.
   */
  setOperatorId(state: AuthState, operatorId: number): void {
    state.operatorId = operatorId;
  },

  /**
   * Set Account id.
   * @param state AuthState.
   */
  setAccountId(state: AuthState, accountId: number): void {
    state.accountId = accountId;
  },

  /**
   * Set lastActiveOperator.
   * @param state AuthState.
   * @param id Operator id.
   */
  setLastActiveOperator(state: AuthState, id: number): void {
    state.lastActiveOperator = id;
  },
  /**
   * Set AuthState unuthorized status.
   * @param state AuthState.
   */
  logout(state: AuthState): void {
    state.authStatus = '';
    state.token = '';
    state.tokenSymbol = '';
    state.operatorId = null;
    state.accountId = null;
  }
};
