import type { SuperAdminState } from './SuperAdminState';
import type {
  LoadingStatusPayload,
  OperatorOnboardingData,
  AccountItem
} from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category SuperAdmin.
 */

export default {
  /**
   * Set loading status.
   * @param state SuperAdmin state.
   * @param payload SuperAdmin loading status for some key.
   */
  setLoadingState(state: SuperAdminState, payload: LoadingStatusPayload): void {
    state.loading[payload.key] = payload.status;
  },

  /**
   * Set active account
   * @param state SuperAdmin state
   * @param account Account item
   */
  setAccount(state: SuperAdminState, account: AccountItem | null): void {
    state.currentAccount = account;
  },

  /**
   * Set account items
   * @param state SuperAdmin state
   * @param accounts Account items
   */

  setAccountItems(state: SuperAdminState, accounts: AccountItem[]): void {
    state.accounts = accounts;
  },

  /**
   * Set account items
   * @param state SuperAdmin state
   * @param value Total number of accounts
   */

  setAccountsTotal(state: SuperAdminState, value: number): void {
    state.accountsTotal = value;
  },

  /**
   * Set active operator
   * @param state SuperAdmin state
   * @param operator Operator item
   */

  setOperator(state: SuperAdminState, operator: OperatorOnboardingData): void {
    state.operator = operator;
  },

  /**
   * Set active operator ID
   * @param state SuperAdmin state
   * @param id operator id
   */

  setOperatorId(state: SuperAdminState, id: number): void {
    state.operatorId = id;
  }
};
