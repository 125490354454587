import type { DashboardState, OperatorWallet } from './DashboardState';
import {
  LoadingStatusPayload,
  AvgNgrStatsItem,
  AvgUsersNGRStatsItem
} from '@/api/schema';

interface GGRItem {
  period: string;
  amount: number;
  amountRatio: number;
}

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category Dashboard
 */

export default {
  /**
   * Set ggr
   * @param state Dashboard state
   * @param data GGR info
   */

  setGGR(
    state: DashboardState,
    { period, amount, amountRatio }: GGRItem
  ): void {
    state.ggr[period].amount = amount;
    state.ggr[period].amountRatio = amountRatio;
  },

  /**
   * set account balance
   * @param state Dashboard state
   * @param data wallet balance and inStake
   */

  setAccountBalance(
    state: DashboardState,
    data: {
      accountBalance: number;
      accountBalanceLastWeekRatio: number;
      walletBalances: number;
      walletBalancesLastWeekRatio: number;
      inStake: number;
      inStakeLastWeekRatio: number;
    }
  ): void {
    state.accountBalance = data.accountBalance;
    state.accountBalanceLastWeekRatio = data.accountBalanceLastWeekRatio;
    state.walletBalances = data.walletBalances;
    state.walletBalancesLastWeekRatio = data.walletBalancesLastWeekRatio;
    state.inStake = data.inStake;
    state.inStakeLastWeekRatio = data.inStakeLastWeekRatio;
  },

  /**
   * set operator wallet
   * @param state Dashboard state
   * @param data
   */

  setOperatorWallet(state: DashboardState, data: OperatorWallet): void {
    state.operatorWallet = data;
  },

  /**
   * Set ngr history
   * @param state Dashboard state
   * @param data ngr history items array
   */

  setNGRHistory(state: DashboardState, data: AvgNgrStatsItem[]): void {
    state.NGRData = data;
  },

  /**
   * Set users ngr history
   * @param state Dashboard state
   * @param data users ngr history items array
   */

  setUsersStakeStatsHistory(
    state: DashboardState,
    data: AvgUsersNGRStatsItem[]
  ): void {
    state.usersStakeData = data.map((el) => ({
      ...el,
      nonStakeUsersCount: el.nonStakeUsersCount + el.notVisitedWidgetUsersCount
    }));
  },

  /**
   * Set loading status.
   * @param state Dashboard state.
   * @param payload Dashboard loading status for some key.
   */
  setLoadingState(state: DashboardState, payload: LoadingStatusPayload): void {
    state.loading[payload.key] = payload.status;
  }
};
