import state from './WithdrawRequestsState';
import actions from './WithdrawRequestsActions';
import mutations from './WithdrawRequestsMutations';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
