import { GameType, ProviderStatus } from '@/api/schema';

/**
 * The initial state for Mining.
 * @category  Mining.
 */

export const defaultWagersState = {
  data: [],
  hasNext: false
};
export default {
  enableMining: false,
  loadMiningStatus: true,
  loadWagers: false,
  wagers: { ...defaultWagersState },
  gameProviders: null
};

/**
 * Describes Mining state.
 * @category Mining.
 */

export interface WagersState {
  data: GameType[];
  hasNext: boolean;
}
export interface MiningState {
  enableMining: boolean;
  loadMiningStatus: boolean;
  loadWagers: boolean;
  wagers: WagersState;
  gameProviders: ProviderStatus[];
}
