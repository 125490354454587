import { defaultPromoState, PromoState } from './PromoState';
import type {
  LoadingStatusPayload,
  PromoCampaignData,
  PromoCampaignListResponse,
  DialogCampaignData
} from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category  Promo Campaigns.
 */

export default {
  /**
   * Set loading status.
   * @param state Promo state.
   * @param payload Promo loading status for some key.
   */
  setLoadingState(state: PromoState, payload: LoadingStatusPayload): void {
    state.loading[payload.key] = payload.status;
  },

  setUpdateState(state: PromoState, payload: boolean): void {
    state.needUpdate = payload;
  },

  /**
   * Reset Promo state.
   * @param state Promo state.
   */
  resetPromoState(state: PromoState): void {
    Object.assign(state, {
      ...defaultPromoState
    });
  },

  /**
   * Set campaings data.
   * @param state Promo state.
   * @param data campaigns data.
   */
  setCampaigns(state: PromoState, data: PromoCampaignListResponse): void {
    state.campaigns.items = data.content;
    state.campaigns.total = data.totalElements;
  },

  /**
   * Set selected campaings data.
   * @param state Promo state.
   * @param data campaign data.
   */
  setSelectedCampaign(state: PromoState, data: PromoCampaignData): void {
    state.selectedCampaign = data ?? null;
  },

  /**
   * Set display status for dialogs.
   * @param state Promo state.
   * @param displayData display data.
   */
  setDialogStatus(state: PromoState, displayData: DialogCampaignData): void {
    state.dialog[displayData.key] = displayData.status;
  },

  setSelectedUsers(state: PromoState, users: string[]): void {
    state.selectedUsers = users;
  }
};
