import { ClientsInfoData, Users } from '@/api/schema';

/**
 * The initial state for ClientsInfo.
 * @category  ClientsInfo.
 */
export default {
  clientsInfo: {
    pancakeSwapUrl: null,
    adminCurrencyTicker: '',
    totalUsers: 0,
    totalUsersBalanceToken: 0,
    totalUsersBalanceUSD: 0,
    totalUsersWeeklyRatio: 0,
    totalWithBalanceUsers: 0,
    totalWithBalanceUsersLastWeekRatio: 0,
    initialDepositLimitsEnabled: false,
    widgetEnabled: null,
    enabledOperatorWithdrawal: false
  },
  users: {
    users: [],
    hasNext: false,
    total: 0
  },
  loadingUsers: true,
  loadingInfo: true
};

/**
 * Describes ClientsInfo state.
 * @category ClientsInfo.
 */
export interface ClientsInfoState {
  clientsInfo: ClientsInfoData;
  users: Users;
  loadingUsers: boolean;
  loadingInfo: boolean;
}
