import type { MobileOperatorState } from './MobileOperatorState';
import type { MobileEventItem, ResponseObject } from '@/api/schema';

/**
 * Set mobile events
 * @param state Mobile operator State
 * @param events Object with mobile events
 */

export default {
  setMobileEvents(
    state: MobileOperatorState,
    events: ResponseObject<MobileEventItem>
  ): void {
    state.mobileEvents.item = events.content;
    state.mobileEvents.total = events.totalElements;
  }
};
