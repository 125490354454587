import type { StakingProgramsState } from './StakingProgramsState';
import { GetStakingProgramsResponse } from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category StakingPrograms.
 */

export default {
  /**
   * Set active staking programs.
   * @param state StakingModule state.
   * @param data active staking programs.
   */
  setStakingPrograms(
    state: StakingProgramsState,
    data: GetStakingProgramsResponse
  ): void {
    state.programs = data.programs || [];
    state.totalInStakingUsers = data.totalInStakingUsers;
    state.totalUsersLastWeekRatio = data.totalUsersLastWeekRatio;
  },

  /**
   * Set loading status.
   * @param state StakingPrograms state.
   * @param status StakingPrograms loading status.
   */
  stakingProgramLoadingState(
    state: StakingProgramsState,
    status: boolean
  ): void {
    state.loading = status;
  }
};
