import { FeatureFlagsState } from './FeatureFlagsState';
import type { FeatureFlagItem } from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category FeatureFlags
 */

export default {
  /**
   * Set feature flags data.
   * @param state FeatureFlags State.
   * @param data feature flags data.
   */
  setFeatureFlagsData(state: FeatureFlagsState, data: FeatureFlagItem[]): void {
    state.features = data;
  },

  /**
   * Set loading features status.
   * @param state FeatureFlags State.
   * @param status loading status.
   */

  setFeatureFlagsLoadingStatus(
    state: FeatureFlagsState,
    status: boolean
  ): void {
    state.loading = status;
  }
};
