import { isSuperAdmin } from '@/helpers/superAdminHelper'
import { isGameProviderAdmin } from '@/helpers/gameProviderAdminHelper'
import { ERole } from "@/plugins/role-permissions/types";

export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    loadComplete: false,
    showLoader: true,
    isSuperAdmin,
    isGameProviderAdmin
  },
  getters: {
    isCopyStakeRole(
      state,
      _,
      rootState: {
        Auth: { role: ERole };
      }
    ): boolean {
        return rootState.Auth.role === ERole.ROLE_COPY_STAKE_ACCOUNT
          || rootState.Auth.role === ERole.ROLE_OPERATOR_COPY_STAKE_ADMIN
          || rootState.Auth.role === ERole.ROLE_OPERATOR_COPY_STAKE_SUPPORT;
    }
  },
  mutations: {
    SET_LOAD_COMPLETE(state) {
      state.loadComplete = true;
    },
    SET_LOADER_HIDE(state) {
      state.showLoader = false;
    },
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay =
        value !== undefined ? value : !state.shallContentShowOverlay;
    }
  },
  actions: {}
};
