import type { ActionContext } from 'vuex';
import type { PaymentsState } from './PaymentsState';
import {
  getPaymentMethods,
  updatePaymentMethodStatus,
  updatePaymentMethod,
  enableDynamicDepositLimit,
  updateSuperadminPaymentMethod,
  enableSuperAdminDynamicDepositLimit,
  getDynamicDepositLimitStatus,
  getSuperAdminDynamicDepositLimitStatus
} from '@/api/Payments';
import type { PaymentMethod } from '@/api/schema';
import { isSuperAdmin } from '@/helpers/superAdminHelper';

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category Payments
 */

export default {
  /**
   * Action used to get payment methods data
   * @param vuex Action Context.
   */
  getPaymentMethods({
    commit
  }: ActionContext<PaymentsState, unknown>): Promise<any> {
    commit('setLoadingState', { key: 'methods', status: true });

    return getPaymentMethods()
      .then((data: PaymentMethod[]): void => {
        commit('setMethods', data);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'methods', status: false });
      });
  },

  /**
   * Action used to update payment method status (active)
   * @param vuex Action Context.
   * @param payload
   */
  updatePaymentMethodStatus(
    { commit }: ActionContext<PaymentsState, unknown>,
    payload: PaymentMethod
  ): Promise<any> {
    commit('setLoadingState', { key: 'methods', status: true });

    return updatePaymentMethodStatus(payload)
      .then((data: PaymentMethod): void => {
        commit('updateMethod', data);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'methods', status: false });
      });
  },

  /**
   * Action used to update payment method
   * @param vuex Action Context.
   * @param payload
   */
  updatePaymentMethod(
    { commit }: ActionContext<PaymentsState, any>,
    payload: PaymentMethod
  ): Promise<any> {
    commit('setLoadingState', { key: 'methods', status: true });

    const method = isSuperAdmin
      ? updateSuperadminPaymentMethod
      : updatePaymentMethod;

    return method(payload)
      .then((data: PaymentMethod): void => {
        commit('updateMethod', data);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'methods', status: false });
      });
  },

  /**
   * Action used to update dynamic deposit limit status.
   * @param vuex Action Context.
   * @param enabled boolean
   */
  enableDynamicDepositLimit(
    { commit, dispatch }: ActionContext<PaymentsState, any>,
    enabled: boolean
  ): Promise<any> {
    commit('setLoadingState', { key: 'methods', status: true });

    const method = isSuperAdmin
      ? enableSuperAdminDynamicDepositLimit
      : enableDynamicDepositLimit;

    return method(enabled)
      .then((): Promise<any> => {
        return dispatch('getDynamicDepositLimitStatus');
      })
      .finally(() => {
        commit('setLoadingState', { key: 'methods', status: false });
      });
  },

  /**
   * Action used to get dynamic deposit limit status.
   * @param vuex Action Context.
   */
  getDynamicDepositLimitStatus({
    commit
  }: ActionContext<PaymentsState, any>): Promise<void> {
    const method = isSuperAdmin
      ? getSuperAdminDynamicDepositLimitStatus
      : getDynamicDepositLimitStatus;

    return method().then((enabled): void => {
      commit('setTokensaleStatus', enabled);
    });
  }
};
