import type { ActionContext } from 'vuex';
import type { MiningState } from './MiningState';
import { getGameProvidersMiningData } from '@/api/GameProviderService';
import { getGameTypes } from '@/api/GameService';
import { getMiningStatus } from '@/api/Mining';
import type {
  ProviderStatus,
  GameTypesResponse,
  PageData,
  MiningStatusResponse
} from '@/api/schema';

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category Mining
 */

export default {
  /**
   * Action used to get game-providers data.
   * @param vuex Action Context.
   * @param data game-providers data.
   */
  getGameProvidersMiningData({
    commit
  }: ActionContext<MiningState, unknown>): void {
    getGameProvidersMiningData()
      .then((data: ProviderStatus[]): void => {
        commit('setGameProvidersData', data);
      })
      .catch((): void => {
        commit('setGameProvidersData', []);
      });
  },

  /**
   * Action used to get game-wagers data.
   * @param vuex Action Context.
   * @param data game-wagers data.
   */
  getWagers(
    { commit }: ActionContext<MiningState, unknown>,
    pageData: PageData
  ): void {
    const { size } = pageData;
    commit('setLoadingWagersStatus', true);
    getGameTypes(size)
      .then((data: GameTypesResponse): void => {
        commit('setGameWagers', { data });
      })
      .catch(() => {
        commit('setLoadingWagersStatus', false);
      })
      .finally(() => {
        commit('setLoadingWagersStatus', false);
      });
  },

  /**
   * Action used to reset game-wagers data.
   * @param vuex Action Context.
   */
  resetWagers({ commit }: ActionContext<MiningState, unknown>): void {
    commit('clearGameWagers');
  },

  /**
   * Action used to get mining status.
   * @param vuex Action Context.
   * @param data mining status.
   */
  getMiningStatus({ commit }: ActionContext<MiningState, unknown>): void {
    commit('setLoadingMiningStatus', true);
    getMiningStatus().then((data: MiningStatusResponse): void => {
      commit('setMiningStatus', data.enabled);
    });
  }
};
