import state from './ClientsInfoState';
import actions from './ClientsInfoActions';
import mutations from './ClientsInfoMutations';
import getters from './ClientsInfoGetters';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
