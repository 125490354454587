import type { ActionContext } from 'vuex';
import type { BlockchainWalletState } from './BlockchainWalletState';
import type {
  DailyBuybacksStats,
  BlockchainTransactions,
  BlockchainWallet,
  FirstBuybackDayResponse,
  BlockchainTransactionsSummary
} from '@/api/schema';
import {
  getWallet,
  activateWallet,
  getBuybackStats,
  getTransactions,
  getFirstBuybackDate,
  getTransactionsSummary
} from '@/api/BlockchainWallet';

import { TransactionsPageData } from '@/api/schema';
import { DateTime } from 'luxon';

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category BlockchainWallet
 */

export default {
  /**
   * Action used to get blockchain wallets.
   * @param vuex Action Context.
   * @param operatorId Operator id
   */
  getWallet(
    { commit }: ActionContext<BlockchainWalletState, unknown>,
    operatorId: string
  ): Promise<void> {
    commit('setLoadingState', {
      key: 'wallets',
      status: true
    });
    return getWallet(operatorId)
      .then((wallets: BlockchainWallet): void => {
        commit('setWallet', wallets);
      })
      .finally(() => {
        commit('setLoadingState', {
          key: 'wallets',
          status: false
        });
      });
  },

  /**
   * Action used to activate/deactivate blockchain wallets.
   * @param vuex Action Context.
   * @param id id wallet.
   * @param active Wallet status.
   */
  activateWallet(
    { commit, dispatch }: ActionContext<BlockchainWalletState, unknown>,
    {
      id,
      active,
      operatorId
    }: { id: number; active: boolean; operatorId: string }
  ): Promise<void> {
    commit('setLoadingState', {
      key: 'wallets',
      status: true
    });
    return activateWallet(id, active).then((): void => {
      dispatch('getWallet', operatorId);
    });
  },

  /**
   * Action used to get blockchain buyback wallet stats.
   * @param vuex Action Context.
   */
  getBuybackStats({
    commit
  }: ActionContext<BlockchainWalletState, unknown>): Promise<void> {
    commit('setLoadingState', {
      key: 'stats',
      status: true
    });

    return getBuybackStats()
      .then((data: DailyBuybacksStats): void => {
        commit('setStats', data);
      })
      .finally(() => {
        commit('setLoadingState', {
          key: 'stats',
          status: false
        });
      });
  },

  /**
   * Action used to get blockchain wallets transactions.
   * @param vuex Action Context.
   * @param pageData transactions pagination data.
   */
  getTransactions(
    { commit }: ActionContext<BlockchainWalletState, unknown>,
    pageData: TransactionsPageData = {
      date: DateTime.utc().toFormat('yyyy-LL-dd'),
      type: 'trading',
      page: 0,
      size: 5
    }
  ): Promise<void> {
    const { type } = pageData;

    commit('setLoadingState', { key: `${type}Transactions`, status: true });

    return getTransactions(pageData)
      .then((transactions: BlockchainTransactions): void => {
        commit('setTransactions', { type, transactions });
      })
      .finally(() => {
        commit('setLoadingState', {
          key: `${type}Transactions`,
          status: false
        });
      });
  },

  /**
   * Action used to get first date of buyback.
   * @param vuex Action Context.
   */
  getFirstBuybackDate({
    commit
  }: ActionContext<BlockchainWalletState, unknown>): void {
    getFirstBuybackDate()
      .then((data: FirstBuybackDayResponse): void => {
        commit('setFirstBuybackDate', data.date);
      })
      .catch((): void => {
        commit('setFirstBuybackDate', null);
      });
  },

  /**
   * Action used to get blockchain wallets transactions summary.
   * @param vuex Action Context.
   * @param pageData transactions data.
   */

  getTransactionsSummary(
    { commit }: ActionContext<BlockchainWalletState, unknown>,
    pageData: TransactionsPageData
  ): void {
    getTransactionsSummary(pageData).then(
      (data: BlockchainTransactionsSummary): void => {
        commit('setTransactionsSummary', {
          type: pageData.type,
          summary: data
        });
      }
    );
  }
};
