import state from './OperatorPaymentsState';
import actions from './OperatorPaymentsActions';
import mutations from './OperatorPaymentsMutations';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
