import { PaymentMethod, PaymentMethodTypeEnum } from '@/api/schema';
import { ERole } from '@/plugins/role-permissions/types';
import type { PaymentsState } from './PaymentsState';

interface RootState {
  Auth: { role: string };
  app: { isSuperAdmin: boolean };
  Payments: {
    disableTokenSale: boolean;
  };
}
interface RootGetters {
  'ClientsInfo/isListed': boolean;
}

const showCryptoTabs = (
  rootState: RootState,
  rootGetters: RootGetters
): boolean => {
  if (rootState.Auth.role === ERole.ROLE_ADMIN || rootState.app.isSuperAdmin)
    return true;
  return (
    !rootState.Payments.disableTokenSale && rootGetters['ClientsInfo/isListed']
  );
};
export default {
  /**
   * Get payment tabs headers.
   * @param state Payments State.
   * @return payments tabs headers.
   */
  paymentTabs(
    state: PaymentsState,
    _: unknown,
    rootState: RootState,
    rootGetters: RootGetters
  ): string[] {
    const cryptoTabs = showCryptoTabs(rootState, rootGetters)
      ? ['CRYPTO DEPOSIT', 'CRYPTO WITHDRAW']
      : [];
    const operatorTabs = ['OPERATOR DEPOSIT', 'OPERATOR WITHDRAW'];

    return [...operatorTabs, ...cryptoTabs];
  },

  /**
   * Get payment tabs data.
   * @param state Payments State.
   * @return payments tabs methods.
   */
  paymentTabsItems(
    state: PaymentsState,
    _: unknown,
    rootState: RootState,
    rootGetters: RootGetters
  ): PaymentMethod[][] {
    const cryptoMethods = showCryptoTabs(rootState, rootGetters)
      ? [
          [
            PaymentMethodTypeEnum.CRYPTO_DEPOSIT_BSC,
            PaymentMethodTypeEnum.CRYPTO_DEPOSIT_ETH
          ],
          [
            PaymentMethodTypeEnum.CRYPTO_WITHDRAW_BSC,
            PaymentMethodTypeEnum.CRYPTO_WITHDRAW_ETH
          ]
        ]
      : [];
    const operatorMethods = [
      [PaymentMethodTypeEnum.OPERATOR_DEPOSIT],
      [PaymentMethodTypeEnum.OPERATOR_WITHDRAW]
    ];

    const findMethod = (methodType: string): PaymentMethod | undefined => {
      return state.methods.find(
        (method: PaymentMethod) => method.type === methodType
      );
    };

    return [...operatorMethods, ...cryptoMethods].reduce((acc, item) => {
      const methods = item.reduce((acc, methodType) => {
        const method = findMethod(methodType);
        if (method) {
          acc.push(method);
        }
        return acc;
      }, []);

      acc.push(methods);

      return acc;
    }, [] as PaymentMethod[][]);
  }
};
