import state from './DashboardState';
import actions from './DashboardActions';
import mutations from './DashboardMutations';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
