import state from './AuthState';
import actions from './AuthActions';
import mutations from './AuthMutations';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
