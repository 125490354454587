import { WithdrawRequestItem } from '@/api/schema';

/**
 * The initial state for  WithdrawRequests.
 * @category   WithdrawRequests.
 */
export default {
  list: [],
  total: 0,
  pendingTotal: 0,
  item: null,
  loading: {
    list: false,
    item: true
  }
};

/**
 * Describes  WithdrawRequests state.
 * @category  WithdrawRequests.
 */
export interface WithdrawRequestsState {
  list: WithdrawRequestItem[];
  total: number;
  pendingTotal: number;
  item: WithdrawRequestItem;
  loading: {
    list: boolean;
    item: boolean;
  };
}
