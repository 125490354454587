import type { ActionContext } from 'vuex';
import type { ClientsInfoState } from './ClientsInfoState';
import type { ClientsInfoData, Users, UsersRequestParams } from '@/api/schema';
import { getClientsInfo, getUserList } from '@/api/ClientInfo';

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category ClientsInfo
 */

export default {
  /**
   * Action used to get clients info.
   * @param vuex Action Context.
   */

  async getClientsInfo({
    commit
  }: ActionContext<ClientsInfoState, unknown>): Promise<void> {
    commit('setLoadingInfo', true);

    return getClientsInfo()
      .then((data: ClientsInfoData): void => {
        commit('setClientsInfo', data);
      })
      .finally(() => {
        commit('setLoadingInfo', false);
      });
  },

  /**
   * Action used to get users list.
   * @param vuex Action Context.
   * @param params
   */
  getUsersList(
    { commit }: ActionContext<Users, unknown>,
    params: UsersRequestParams
  ): void {
    commit('setLoadingStatus', true);
    getUserList(params)
      .then((data: Users): void => {
        commit('setUsers', data);
      })
      .catch((e: Error): any => {
        console.log(e);
        commit('setUsers', {
          users: [],
          hasNext: false,
          total: 0
        } as Users);
      })
      .finally((): void => {
        commit('setLoadingStatus', false);
      });
  }
};
