import type { ActionContext } from 'vuex';
import type { WithdrawRequestsState } from './WithdrawRequestsState';

import {
  CommentWithdrawRequestPayload,
  GetWithdrawRequestByIdPayload,
  GetWithdrawRequestsPayload,
  GetWithdrawRequestsResponse,
  WithdrawRequestItem
} from '@/api/schema';

import {
  commentWithdrawRequest,
  getWithdrawRequestById,
  getWithdrawRequests
} from '@/api/WithdrawRequests';
import { WITHDRAW_REQUEST_STATUSES } from '@/helpers/withdrawRequestHelpers';

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category Payments
 */

export default {
  /**
   * Action used to get withdraw requests list
   * @param vuex Action Context.
   * @param payload
   */
  getList(
    { commit }: ActionContext<WithdrawRequestsState, unknown>,
    payload: GetWithdrawRequestsPayload
  ): Promise<any> {
    commit('setLoadingState', { key: 'list', status: true });

    return getWithdrawRequests(payload)
      .then((data: GetWithdrawRequestsResponse): void => {
        commit('setList', data.content);
        commit('setTotal', data.totalElements);

        if (
          payload.state === WITHDRAW_REQUEST_STATUSES.CREATED &&
          ['operatorUserId', 'id', 'from', 'to'].every(
            (field) => !payload[field]
          )
        ) {
          commit('setPendingTotal', data.totalElements);
        }
      })
      .finally(() => {
        commit('setLoadingState', { key: 'list', status: false });
      });
  },

  /**
   * Action used to get withdraw request by id
   * @param vuex Action Context.
   * @param payload
   */
  getItem(
    { commit, state }: ActionContext<WithdrawRequestsState, unknown>,
    payload: GetWithdrawRequestByIdPayload
  ): Promise<any> {
    commit('setLoadingState', { key: 'item', status: true });

    if (state.item) commit('setItem', null);

    return getWithdrawRequestById(payload)
      .then((data: WithdrawRequestItem): void => {
        commit('setItem', data);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'item', status: false });
      });
  },

  /**
   * Action used to add comment to withdraw request
   * @param vuex Action Context.
   * @param payload
   */
  comment(
    { commit }: ActionContext<WithdrawRequestsState, unknown>,
    payload: CommentWithdrawRequestPayload
  ): Promise<any> {
    commit('setLoadingState', { key: 'item', status: true });

    return commentWithdrawRequest(payload).finally(() => {
      commit('setLoadingState', { key: 'item', status: false });
    });
  }
};
