import {
  DailyBuybacksStats,
  BlockchainTransactions,
  BlockchainTransactionsSummary,
  BlockchainWalletItem
} from '@/api/schema';

/**
 * The initial state for TradingWallet.
 * @category  TradingWallet.
 */
export default {
  wallets: [],
  stats: {},
  transactions: {
    trading: {
      hasNext: false,
      total: 0,
      transactions: []
    },
    buyback: {
      hasNext: false,
      total: 0,
      transactions: []
    }
  },
  summary: {
    trading: {
      totalAmountUsd: 0,
      totalFeeBNB: 0,
      totalFeeUsd: 0
    },
    buyback: {
      totalAmountUsd: 0,
      totalFeeBNB: 0,
      totalFeeUsd: 0
    }
  },
  loading: {
    wallets: false,
    stats: false,
    tradingTransactions: false,
    buybackTransactions: false
  },
  firstDate: null
};

/**
 * Describes TradingWallet state.
 * @category TradingWallet.
 */
export interface BlockchainWalletState {
  wallets: BlockchainWalletItem[];
  stats: DailyBuybacksStats;
  transactions: {
    trading: BlockchainTransactions;
    buyback: BlockchainTransactions;
  };
  summary: {
    trading: BlockchainTransactionsSummary;
    buyback: BlockchainTransactionsSummary;
  };
  loading: {
    wallets: boolean;
    stats: boolean;
    tradingTransactions: boolean;
    buybackTransactions: boolean;
  };
  firstDate: string | null;
}
