import { getCurrencySymbol } from '@/helpers/currencyHelper';
import type { TokensInfoState } from './TokensInfoState';
import type {
  TokenPriceData,
  FrozenTokensData,
  TokenInfoData
} from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category TokenManagement
 */

export default {
  /**
   * Set total tokens frozen data.
   * @param state TokensInfo State.
   * @param amount Frozen tokens amount.
   */
  setFrozenTokensData(state: TokensInfoState, data: FrozenTokensData): void {
    state.frozenTokens = data;
  },

  /**
   * Set token price data.
   * @param state TokensInfo State.
   * @param data Token price data.
   */
  setTokenPriceData(state: TokensInfoState, data: TokenPriceData): void {
    state.tokenPrice = {
      ...data,
      rateCurrencySymbol: getCurrencySymbol(data.rateCurrencyTicker)
    };
  },

  /**
   * Set token info data.
   * @param state TokensInfo State.
   * @param data Token info data.
   */
  setTokenInfoData(state: TokensInfoState, data: TokenInfoData[]): void {
    state.tokenInfo = data;
  }
};
