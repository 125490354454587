import { MiningState, defaultWagersState } from './MiningState';
import type { ProviderStatus, GameTypesResponse, PageData } from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category Mining
 */

interface SetGameWagersData {
  pageData: PageData;
  data: GameTypesResponse;
}

export default {
  /**
   * Set game-providers data.
   * @param state Mining State.
   * @param data game-providers data.
   */
  setGameProvidersData(state: MiningState, data: ProviderStatus[]): void {
    state.gameProviders = data;
  },

  /**
   * Set loading mining status.
   * @param state Mining State.
   * @param status loading status.
   */

  setLoadingWagersStatus(state: MiningState, status: boolean): void {
    state.loadWagers = status;
  },

  /**
   * Set game-wagers data.
   * @param state Mining State.
   * @param data game-wagers data.
   */

  setGameWagers(state: MiningState, { data }: SetGameWagersData): void {
    state.wagers.data = data.gameTypes;
    state.wagers.hasNext = data.hasNext;
  },

  /**
   * Clear game-wagers data.
   * @param state Mining State.
   */

  clearGameWagers(state: MiningState): void {
    state.wagers = { ...defaultWagersState };
  },

  /**
   * Set mining status.
   * @param state Mining State.
   * @param data mining status.
   */

  setMiningStatus(state: MiningState, status: boolean): void {
    state.enableMining = status;
    state.loadMiningStatus = false;
  },

  /**
   * Set loading mining status.
   * @param state Mining State.
   * @param status loading status.
   */

  setLoadingMiningStatus(state: MiningState, status: boolean): void {
    state.loadMiningStatus = status;
  }
};
