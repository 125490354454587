













import MobileLockDeviceIcon from '@/assets/images/mobile-lock-device.svg?inline';

export default {
  name: 'MobileLockView',
  components: { MobileLockDeviceIcon }
};
