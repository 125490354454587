import type { ActionContext } from 'vuex';
import { OperatorState } from './OperatorState';
import type { AxiosError } from 'axios';
import { isSuperAdmin } from '@/helpers/superAdminHelper';
import type {
  AccountUserUpdatePayload,
  CompanyInfo,
  DeleteRequestData,
  EAccountNotification,
  EnableWidgetPayload,
  IOperatorEventsParams,
  OperatorLanguages,
  OperatorUserCreatePayload,
  TAccountStorage,
  TOperatorEventsList
} from '@/api/schema';

import {
  createAccountUser,
  deleteAccountUser,
  getAccountStorage,
  getAccountUsers,
  getCompanyInfo,
  getOperatorEvents,
  getOperatorLanguages,
  saveCompanyInfo,
  updateAccountStorage,
  updateAccountUser,
  updateOperatorCurrency,
  updateOperatorDefaultLanguage,
  updateOperatorEvents,
  updateOperatorWidgetLanguagesList,
  updateOperatorWidgetStatus
} from '@/api/Operator';
import {
  createOperatorManagerUser,
  deleteOperatorManagerUser,
  getOperatorManagementUsers,
  updateOperatorManagerUser
} from '@/api/SuperAdmin';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { OnboardingState } from '@/store/modules/onboarding/OnboardingState';

export default {
  /**
   * Get widget languages.
   */
  getOperatorLanguages({
    commit
  }: ActionContext<OperatorState, unknown>): Promise<unknown> {
    commit('setLoadingState', { key: 'languages', status: true });

    return getOperatorLanguages()
      .then((result) => {
        commit('setDefaultLanguage', result.defaultLanguage || 'en');
        commit('setLanguages', result.languages);
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'languages', status: false });
      });
  },

  /**
   * Update widget default language.
   */
  updateOperatorDefaultLanguage(
    { commit }: ActionContext<OperatorState, unknown>,
    language: string
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'defaultLanguage', status: true });

    return updateOperatorDefaultLanguage(language)
      .then((result: OperatorLanguages) => {
        commit('setDefaultLanguage', result.defaultLanguage);
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
        throw new Error('');
      })
      .finally(() => {
        commit('setLoadingState', { key: 'defaultLanguage', status: false });
      });
  },

  /**
   * Update the list of available widget languages
   */
  updateOperatorWidgetLanguagesList(
    { commit }: ActionContext<OperatorState, unknown>,
    languages: string[]
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'languages', status: true });

    return updateOperatorWidgetLanguagesList(languages)
      .then((result) => {
        commit('setLanguages', result);
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
        throw new Error('');
      })
      .finally(() => {
        commit('setLoadingState', { key: 'languages', status: false });
      });
  },

  /**
   * Get operator users.
   */
  getAccountUsers(
    { commit }: ActionContext<OperatorState, any>,
    id: number
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'users', status: true });

    const handler = isSuperAdmin ? getOperatorManagementUsers : getAccountUsers;

    return handler(id)
      .then((result) => {
        commit('setUsers', result);
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'users', status: false });
      });
  },

  /**
   * Create operator user.
   */
  createAccountUser(
    { commit, state }: ActionContext<OperatorState, any>,
    data: OperatorUserCreatePayload
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'users', status: true });

    const handler = isSuperAdmin
      ? createOperatorManagerUser
      : createAccountUser;

    return handler(data)
      .then((result) => {
        commit('setUsers', [...state.users, result]);
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'users', status: false });
      });
  },

  /**
   * Update account user.
   */
  updateAccountUser(
    { commit, dispatch }: ActionContext<OperatorState, any>,
    data: AccountUserUpdatePayload
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'users', status: true });

    const handler = isSuperAdmin
      ? updateOperatorManagerUser
      : updateAccountUser;

    return handler(data)
      .then(() => {
        dispatch('getAccountUsers', data.accountId);
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'users', status: false });
      });
  },

  /**
   * Delete operator user.
   */
  deleteAccountUser(
    { commit, state }: ActionContext<OperatorState, any>,
    data: DeleteRequestData
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'users', status: true });

    const handler = isSuperAdmin
      ? deleteOperatorManagerUser
      : deleteAccountUser;

    return handler(data)
      .then(() => {
        commit(
          'setUsers',
          state.users.filter((v) => v.id !== data.userId)
        );
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'users', status: false });
      });
  },

  /**
   * Update operator default currency.
   */
  updateOperatorCurrency(
    { commit }: ActionContext<OperatorState, unknown>,
    currency: string
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'currency', status: true });

    return updateOperatorCurrency(currency)
      .then(() => {
        commit('ClientsInfo/setCurrency', currency, { root: true });
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'currency', status: false });
      });
  },

  /**
   * Enable/disable widget.
   */
  updateOperatorWidgetStatus(
    { commit }: ActionContext<OperatorState, unknown>,
    payload: EnableWidgetPayload
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'widgetStatus', status: true });

    return updateOperatorWidgetStatus(payload)
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'widgetStatus', status: false });
      });
  },

  /**
   * Get company info.
   */
  getCompanyInfo({
    commit
  }: ActionContext<OperatorState, unknown>): Promise<unknown> {
    commit('setLoadingState', { key: 'companyInfo', status: true });

    return getCompanyInfo()
      .then((result) => {
        commit('setCompanyInfo', result);
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'companyInfo', status: false });
      });
  },

  /**
   * Save company info.
   */
  saveCompanyInfo(
    { commit, dispatch }: ActionContext<OperatorState, unknown>,
    data: CompanyInfo
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'companyInfo', status: true });

    return saveCompanyInfo(data)
      .then(() => dispatch('getCompanyInfo'))
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'companyInfo', status: false });
      });
  },

  async getAccountStorage({
    commit,
    dispatch,
    rootState
  }: ActionContext<
    OperatorState,
    { Onboarding: OnboardingState }
  >): Promise<unknown> {
    if (!rootState.Onboarding.operators.length) {
      await dispatch('Onboarding/getAllOperators', null, { root: true });
    }

    return getAccountStorage()
      .then((data) => {
        commit('setAccountStorage', data);
        commit('setAccountStorageError', false);
      })
      .catch(() => commit('setAccountStorageError', true));
  },

  updateAccountStorage(
    { commit }: ActionContext<OperatorState, unknown>,
    payload: TAccountStorage
  ): Promise<unknown> {
    return updateAccountStorage(payload)
      .then(() => {
        commit('setAccountStorage', payload);
        commit('setAccountStorageError', false);
      })
      .catch(() => commit('setAccountStorageError', true));
  },

  saveNotificationState(
    { state: { storage }, dispatch }: ActionContext<OperatorState, unknown>,
    notificationType: EAccountNotification
  ): Promise<unknown> {
    if (storage.notification.includes(notificationType)) return;

    const notification = [...new Set(storage.notification), notificationType];

    return dispatch('updateAccountStorage', { ...storage, notification });
  },

  /**
   * Get operator events.
   */
  getOperatorEvents({
    commit
  }: ActionContext<OperatorState, unknown>): Promise<void> {
    commit('setLoadingState', { key: 'operatorEvents', status: true });

    return getOperatorEvents()
      .then((result: TOperatorEventsList) => {
        commit('setOperatorEvents', result);
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'operatorEvents', status: false });
      });
  },

  /**
   * Update operator events status.
   */
  updateOperatorEvents(
    { commit, dispatch }: ActionContext<OperatorState, unknown>,
    params: IOperatorEventsParams
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'operatorEvents', status: true });

    return updateOperatorEvents(params)
      .catch((err: AxiosError) => {
        errorToastMessage(err);
        throw new Error('');
      })
      .finally(() => {
        commit('setLoadingState', { key: 'operatorEvents', status: false });
        return dispatch('getOperatorEvents');
      });
  }
};
