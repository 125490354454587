import Vue from 'vue';
import { ActionContext } from 'vuex';
import {
  accountBalance,
  operator,
  getAvgNgrStats,
  getUserStakeStats
} from '@/api/DashboardInfo';
import type {
  AccountBalance,
  OperatorItem,
  AvgUsersNGRStatsItem,
  AvgNgrStatsItem,
  TransferPayload,
  WithdrawWalletPayload,
  WithdrawWalletResponce,
  UserGraphRequest
} from '@/api/schema';
import { DashboardState } from '@/store/modules/dashboard/DashboardState';
import { transferTokens, withdrawTokens } from '@/api/DashboardInfo';
import { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';

export default {
  /**
   * Action used to get account balance info
   * @param commit vuex Action Context.
   */

  async getAccountsBalance({
    commit
  }: ActionContext<AccountBalance, unknown>): Promise<void> {
    return await accountBalance().then((data: AccountBalance) => {
      commit('setAccountBalance', data);
    });
  },

  /**
   * Action used to get operator wallets info
   * @param commit vuex Action Context.
   */

  getOperator({ commit }: ActionContext<OperatorItem[], unknown>): void {
    operator().then((data: OperatorItem[]) => {
      const wallet = {};

      data.forEach((item: OperatorItem): void => {
        wallet[item.walletType] = {
          balance: item.balance,
          currency: item.currency,
          hashId: item.hashId
        };
      });
      commit('setOperatorWallet', wallet);
    });
  },

  /**
   * Action used to get ngr history data.
   * @param commit vuex Action Context.
   * @param requestData date period params
   */

  async getNGRHistory(
    { commit }: ActionContext<AvgNgrStatsItem, unknown>,
    requestData: UserGraphRequest
  ): Promise<void> {
    getAvgNgrStats(requestData).then((history: AvgNgrStatsItem[]) => {
      commit(
        'setNGRHistory',
        history.map((el) => ({ ...el, date: el.calculationDate }))
      );
    });
  },
  /**
   * Action used to get users ngr history data.
   * @param commit vuex Action Context.
   * @param requestData date period params
   */

  async getUserStakeStats(
    { commit }: ActionContext<AvgUsersNGRStatsItem, unknown>,
    requestData: UserGraphRequest
  ): Promise<void> {
    return await getUserStakeStats(requestData).then(
      (history: AvgUsersNGRStatsItem[]) => {
        commit('setUsersStakeStatsHistory', history);
      }
    );
  },

  /**
   * Transfer tokens between operator wallets.
   */
  transferTokens(
    { commit, dispatch }: ActionContext<DashboardState, unknown>,
    payload: TransferPayload
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'transfer', status: true });

    return transferTokens(payload)
      .then(() => {
        return dispatch('getOperator');
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'transfer', status: false });
      });
  },

  /**
   * Withdraw tokens from operator wallets.
   */
  withdrawTokens(
    { commit, dispatch }: ActionContext<DashboardState, unknown>,
    payload: WithdrawWalletPayload
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'withdraw', status: true });

    return withdrawTokens(payload)
      .then((data: WithdrawWalletResponce) => {
        Vue.prototype.$toast.success(
          `${data.amount} ${data.currency} has been withdrawn successfully`
        );
        return dispatch('getOperator');
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'withdraw', status: false });
      });
  }
};
