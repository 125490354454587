import state from './MobileOperatorState';
import mutations from './MobileOperatorMutation';
import actions from './MobileOperatorAction';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  mutations,
  actions,
  namespaced: true
};
