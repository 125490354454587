import { TokenPriceData, FrozenTokensData, TokenInfoData } from '@/api/schema';

/**
 * The initial state for TokensInfo.
 * @category  TokensInfo.
 */
export default {
  frozenTokens: {
    amount: 0,
    fromLastWeek: 0,
    amountUsd: 0
  },
  tokenPrice: {
    currency: null,
    dailyRatio: 0,
    marketCup: 0,
    marketCupDailyRatio: 0,
    price: 0,
    weeklyRatio: 0,
    rateCurrencyTicker: null,
    rateCurrencySymbol: null,
    priceInUsd: 1,
    usdToUsdtExchangeRate: 1
  },
  tokenInfo: []
};

export interface TokenPriceState extends TokenPriceData {
  rateCurrencySymbol: string;
}

/**
 * Describes TokensInfo state.
 * @category TokensInfo.
 */
export interface TokensInfoState {
  frozenTokens: FrozenTokensData;
  tokenPrice: TokenPriceState;
  tokenInfo: TokenInfoData[];
}
