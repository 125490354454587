import type { OperatorPaymentsState } from './OperatorPaymentsState';
import type {
  LoadingStatusPayload,
  OperatorBalance,
  OperatorWalletForDeposit,
  OperatorInvoiceHistory
} from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category Operator Payments
 */

export default {
  /**
   * Set loading status.
   * @param state Operator Payments state.
   * @param payload Operator loading status for some key.
   */
  setLoadingState(
    state: OperatorPaymentsState,
    payload: LoadingStatusPayload
  ): void {
    state.loading[payload.key] = payload.status;
  },

  /**
   * Set operator balance data.
   * @param state Operator Payments.
   * @param data balance data.
   */
  setBalance(state: OperatorPaymentsState, data: OperatorBalance): void {
    state.balance = data;
  },

  /**
   * Set operator wallet data.
   * @param state Operator Payments.
   * @param data wallet data.
   */
  setWallet(
    state: OperatorPaymentsState,
    data: OperatorWalletForDeposit
  ): void {
    state.wallet = data;
  },

  /**
   * Set add funds modal state.
   * @param state Operator Payments.
   * @param value
   */
  setAddFundsModalState(state: OperatorPaymentsState, value: boolean): void {
    state.addFundsModalState = value;
  },

  /**
   * Set operator invoice history.
   * @param state Operator Payments.
   * @param data invoice history data.
   */
  setInvoiceHistory(
    state: OperatorPaymentsState,
    data: OperatorInvoiceHistory
  ): void {
    state.invoicesHistory = data;
  }
};
