import state from './TokensInfoState';
import actions from './TokensInfoActions';
import mutations from './TokensInfoMutations';
import getters from './TokensInfoGetters';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
