import { Status } from '@/helpers/operatorHelpers';
import type { SuperAdminState } from './SuperAdminState';
import type {
  AccountsOperatorsSelectItem,
  OperatorOnboardingForm
} from '@/api/schema';

// Getters are computable properties with an internal
// caching mechanism based on its dependencies.

export default {
  /**
   * Get users role items.
   * @returns users role items.
   */
  roleSelectData: (): AccountsOperatorsSelectItem[] => [
    {
      text: 'Admin',
      value: 'ROLE_OPERATOR_ADMIN'
    },
    {
      text: 'Manager',
      value: 'ROLE_OPERATOR_MANAGER'
    },
    {
      text: 'Support',
      value: 'ROLE_OPERATOR_SUPPORT'
    }
  ],

  /**
   * Get operator onboarding form
   * @param state onboarding state
   * @return onboarding form
   */

  onboardingForm(state: SuperAdminState): OperatorOnboardingForm {
    const {
      ggr,
      name,
      test,
      tokenName,
      tokenNumber,
      tokenPrice,
      tokenTicker,
      url
    } = state.operator;

    return {
      ggr,
      name,
      test,
      tokenName,
      tokenNumber,
      tokenPrice,
      tokenTicker,
      url
    };
  },

  /**
   * Get operator status items.
   * @returns operator status items.
   */
  statusSelectData: (): AccountsOperatorsSelectItem[] => {
    return Object.keys(Status).map((key) => ({
      text: Status[key],
      value: Status[key]
    }));
  },

  /**
   * Get operator liquidity pool status.
   * @return liquidity pool status.
   */
  isLiquidityPoolCreated(state: SuperAdminState): boolean {
    return !!state.operator?.liquidityPoolAddress;
  }
};
