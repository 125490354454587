import state from './StakingProgramsState';
import actions from './StakingProgramsActions';
import mutations from './StakingProgramsMutations';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
