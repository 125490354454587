import {
  toggleOperatorIPStatus,
  accounts,
  getOperatorById,
  getAccountByID
} from '@/api/SuperAdmin';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';
import type { ActionContext } from 'vuex';
import type { SuperAdminState } from './SuperAdminState';
import type {
  AccountsOperatorsItem,
  ToggleIPPayload,
  AccountsParams,
  AccountItem
} from '@/api/schema';

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category SuperAdmin.
 */

export default {
  /**
   * Action to enable/disable (toggle) operator IP
   * @param payload operator id and id of ip.
   */
  toggleOperatorIPStatus(
    { commit }: ActionContext<SuperAdminState, unknown>,
    payload: ToggleIPPayload
  ): void {
    commit('setLoadingState', {
      key: 'operatorIPs',
      status: true
    });

    const { id, idIp } = payload;

    toggleOperatorIPStatus(id, idIp)
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally((): void => {
        commit('setLoadingState', {
          key: 'operatorIPs',
          status: false
        });
      });
  },

  /**
   * Action to get all account
   * @param commit
   * @param page
   * @param params params for query
   */
  getAccounts(
    { commit }: ActionContext<SuperAdminState, unknown>,
    params: AccountsParams
  ): void {
    commit('setLoadingState', {
      key: 'accounts',
      status: true
    });

    accounts(params)
      .then((data) => {
        commit('setAccountItems', data.content);
        commit('setAccountsTotal', data.totalElements);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        commit('setLoadingState', {
          key: 'accounts',
          status: false
        });
      });
  },

  /**
   * Action to get account detail
   * @param commit
   * @param id
   */
  getAccountDetailsById(
    { commit }: ActionContext<SuperAdminState, unknown>,
    id: number
  ): Promise<any> {
    commit('setLoadingState', {
      key: 'account',
      status: true
    });

    return getAccountByID(id)
      .then((data: AccountItem) => {
        commit('setAccount', data);
      })
      .catch((e: AxiosError) => {
        console.log(e);
        commit('setAccount', null);
      })
      .finally(() => {
        commit('setLoadingState', {
          key: 'account',
          status: false
        });
      });
  },

  getOperatorDetailsById(
    { commit, state, dispatch }: ActionContext<SuperAdminState, unknown>,
    id: number
  ): Promise<any> {
    dispatch('Payments/getDynamicDepositLimitStatus', null, { root: true });
    return getOperatorById(id || state.operatorId)
      .then((data: AccountsOperatorsItem) => {
        commit('setOperator', data);
        const TokenSymbol = data?.tokenTicker ?? 'TOKEN';
        commit('Auth/setTokenSymbol', TokenSymbol, { root: true });
      })
      .catch((e: AxiosError) => {
        console.log(e);
        commit('setOperator', null);
      });
  }
};
