import type { ClientsInfoState } from './ClientsInfoState';
import type { ClientsInfoData, Users } from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category ClientsInfo
 */

export default {
  /**
   * Set clients info.
   * @param state ClientsInfo State.
   * @param data clients info data.
   */

  setClientsInfo(state: ClientsInfoState, data: ClientsInfoData): void {
    state.clientsInfo = data;
  },

  /**
   * Set users datta.
   * @param state ClientsInfo State.
   * @param data users data.
   */

  setUsers(state: ClientsInfoState, data: Users): void {
    state.users = data;
  },

  /**
   * Set loading users status.
   * @param state ClientsInfo State.
   * @param status users data loading status.
   */
  setLoadingStatus(state: ClientsInfoState, status: boolean): void {
    state.loadingUsers = status;
  },

  /**
   * Set loading info status.
   * @param state ClientsInfo State.
   * @param status info data loading status.
   */
  setLoadingInfo(state: ClientsInfoState, status: boolean): void {
    state.loadingInfo = status;
  },

  /**
   * Set currency.
   * @param state ClientsInfo State.
   * @param currency
   */
  setCurrency(state: ClientsInfoState, currency: string): void {
    if (state.clientsInfo) {
      state.clientsInfo.adminCurrencyTicker = currency;
    }
  }
};
