


















































































import { mapActions, mapState } from 'vuex';
import * as vr from '@/helpers/validation';
import { roundNumber } from '@/helpers/roundNumber';
import USDTIcon from '@/assets/images/payments/usdt.svg?inline';
import { OperatorPaymentsState } from '@/store/modules/operator-payments/OperatorPaymentsState';
import QrcodeVue from 'qrcode.vue';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';

export default {
  name: 'OperatorBalanceAddFundsDialog',
  components: {
    USDTIcon,
    QrcodeVue
  },
  data(): any {
    return {
      formIsValid: false,
      submitted: false,
      selectedMethod: 'usdt',
      formData: {
        amount: undefined
      },
      maxAmount: 100000
    };
  },
  computed: {
    ...mapState('OperatorPayments', [
      'addFundsModalState',
      'invoice',
      'wallet'
    ]),
    ...mapState('OperatorPayments', {
      walletIsLoading: (state: OperatorPaymentsState) => state.loading.wallet
    }),
    ...mapState('app', ['isSuperAdmin']),
    rules(): any {
      return {
        amount: [
          (v) => vr.required(v),
          (v) => vr.validNumber(v),
          (v) => vr.integer(v),
          (v) => vr.positiveNumber(v),
          (v) => vr.lte(v, this.maxAmount)
        ]
      };
    }
  },
  watch: {
    addFundsModalState(newVal: boolean): void {
      if (newVal) {
        this.submitted = false;
        this.$refs.form?.reset();
      }
    }
  },
  methods: {
    ...mapActions('OperatorPayments', [
      'setAddFundsModalState',
      'getOperatorWallet'
    ]),
    closeDialog(): void {
      this.setAddFundsModalState(false);
    },
    formatNumber(value: number): string {
      if (!+value) {
        return '0';
      }

      return roundNumber(value, 7);
    },
    async submitHandler(): Promise<void> {
      {
        const form = this.$refs.form;
        form.validate();
        this.$nextTick(async (): Promise<void> => {
          if (this.formIsValid) {
            this.submitted = true;
            try {
              if (!this.wallet?.address) {
                await this.getOperatorWallet();
              }
            } catch (err) {
              this.submitted = false;
              this.$toast.error('Something went wrong! Please, try again.');
            }
          }
        });
      }
    },
    copyToClipBoard
  }
};
