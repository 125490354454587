import { AccountItem, OperatorOnboardingData } from '@/api/schema';

/**
 * The initial state for SuperAdmin.
 * @category  SuperAdmin.
 */
export default {
  users: [],
  operatorsSelect: [],
  loading: {
    account: false,
    operators: true,
    accounts: true,
    operatorIPs: false
  },
  currentAccount: null,
  accounts: [],
  accountsTotal: 0,
  operator: null,
  operatorId: null
};

/**
 * Describes SuperAdmin state.
 * @category SuperAdmin.
 */
export interface SuperAdminState {
  loading: {
    account: false;
    accounts: boolean;
    operators: boolean;
    operatorIPs: boolean;
  };
  currentAccount: AccountItem | null;
  accounts: AccountItem[];
  accountsTotal: number;
  operator: OperatorOnboardingData | null;
  operatorId: number | null;
}
