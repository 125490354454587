import type { ActionContext } from 'vuex';
import type { StakingProgramsState } from './StakingProgramsState';
import type {
  NewStakingProgramData,
  UpdateStakingProgramRequestData,
  GetStakingProgramsResponse
} from '@/api/schema';
import {
  getStakingPrograms,
  deleteStakingProgram,
  createStakingProgram,
  updateStakingProgram,
  startAllStakingProgram,
  stopAllStakingProgram,
  setDefaultCoefficient
} from '@/api/StakingPrograms';
import { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category StakingPrograms.
 */

export default {
  /**
   * Action to get staking programs.
   */
  async getStakingPrograms({
    commit
  }: ActionContext<StakingProgramsState, unknown>): Promise<void> {
    commit('stakingProgramLoadingState', true);
    return await getStakingPrograms()
      .then((data: GetStakingProgramsResponse): void => {
        commit('setStakingPrograms', data);
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally((): void => {
        commit('stakingProgramLoadingState', false);
      });
  },

  /**
   * Action to Create Program.
   * @param data Program data.
   */
  createStakingProgram(
    { commit, dispatch }: ActionContext<StakingProgramsState, unknown>,
    data: NewStakingProgramData
  ): Promise<unknown> {
    commit('stakingProgramLoadingState', true);
    return createStakingProgram(data)
      .then((): void => {
        dispatch('getStakingPrograms');
      })
      .finally((): void => {
        commit('stakingProgramLoadingState', false);
      });
  },

  /**
   * Action to update Program.
   * @param data Program data.
   */
  updateStakingProgram(
    { commit, dispatch }: ActionContext<StakingProgramsState, unknown>,
    data: UpdateStakingProgramRequestData
  ): Promise<unknown> {
    commit('stakingProgramLoadingState', true);
    return updateStakingProgram(data)
      .then((): void => {
        dispatch('getStakingPrograms');
      })
      .finally((): void => {
        commit('stakingProgramLoadingState', false);
      });
  },

  /**
   * Action to set default coefficient
   * @param value.
   */
  setDefaultCoefficient(
    { commit }: ActionContext<StakingProgramsState, unknown>,
    value: number
  ): Promise<unknown> {
    commit('stakingProgramLoadingState', true);
    return setDefaultCoefficient(value).finally((): void => {
      commit('stakingProgramLoadingState', false);
    });
  },

  /**
   * Action to delete Program.
   * @param id Program id.
   */
  deleteStakingProgram(
    { dispatch, commit }: ActionContext<StakingProgramsState, unknown>,
    id: number
  ): Promise<unknown> {
    commit('stakingProgramLoadingState', true);
    return deleteStakingProgram(id)
      .then((): void => {
        dispatch('getStakingPrograms');
      })
      .finally((): void => {
        commit('stakingProgramLoadingState', false);
      });
  },

  /**
   * Action to start all program.
   * @param id Program id.
   */
  startAllStakingProgram({
    dispatch,
    commit
  }: ActionContext<StakingProgramsState, unknown>): Promise<unknown> {
    commit('stakingProgramLoadingState', true);
    return startAllStakingProgram()
      .then((): void => {
        dispatch('getStakingPrograms');
      })
      .finally((): void => {
        commit('stakingProgramLoadingState', false);
      });
  },

  /**
   * Action to stop all programs.
   * @param id Program id.
   */
  stopAllStakingProgram({
    dispatch,
    commit
  }: ActionContext<StakingProgramsState, unknown>): Promise<unknown> {
    commit('stakingProgramLoadingState', true);
    return stopAllStakingProgram()
      .then((): void => {
        dispatch('getStakingPrograms');
      })
      .finally((): void => {
        commit('stakingProgramLoadingState', false);
      });
  }
};
