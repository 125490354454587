import type {
  CompanyInfo,
  AccountUser,
  TAccountStorage,
  TOperatorEventsList
} from '@/api/schema';

/**
 * The initial state for Operator.
 * @category  Operator.
 */

export default {
  users: [],
  defaultLanguage: null,
  languages: [],
  companyInfo: {},
  storage: { notification: [] },
  storageError: false,
  operatorEvents: {},
  loading: {
    users: false,
    languages: false,
    defaultLanguage: false,
    currency: false,
    widgetStatus: false,
    companyInfo: false,
    operatorEvents: false
  }
};

/**
 * Describes Operator state.
 * @category Operator.
 */
export interface OperatorState {
  users: AccountUser[];
  defaultLanguage: string | null;
  languages: string[];
  companyInfo: CompanyInfo;
  storage: TAccountStorage;
  storageError: boolean;
  operatorEvents: TOperatorEventsList;
  loading: {
    users: boolean;
    languages: boolean;
    defaultLanguage: boolean;
    currency: boolean;
    widgetStatus: boolean;
    companyInfo: boolean;
    operatorEvents: boolean;
  };
}
