import { MobileEventItem } from '@/api/schema';

export default {
  mobileEvents: {
    item: [],
    total: 0
  }
};

export interface MobileOperatorState {
  mobileEvents: {
    item: MobileEventItem[];
    total: number;
  };
}
