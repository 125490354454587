import type { PaymentsState } from './PaymentsState';
import type { LoadingStatusPayload, PaymentMethod } from '@/api/schema';
import { OnboardingState } from '@/store/modules/onboarding/OnboardingState';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category TokenManagement
 */

export default {
  /**
   * Set loading status.
   * @param state Payments state.
   * @param payload Payments loading status for some key.
   */
  setLoadingState(state: OnboardingState, payload: LoadingStatusPayload): void {
    state.loading[payload.key] = payload.status;
  },

  /**
   * Set methods
   * @param state Payments State.
   * @param data
   */
  setMethods(state: PaymentsState, data: PaymentMethod[]): void {
    state.methods = [...data];
  },

  /**
   * Update method
   * @param state Payments State.
   * @param data
   */
  updateMethod(state: PaymentsState, data: PaymentMethod): void {
    const index = state.methods.findIndex((item) => item.id === data.id);

    if (index > -1) {
      state.methods[index] = data;
      state.methods = [...state.methods];
    }
  },

  /**
   * Set tokensale status.
   * @param state Payments State.
   * @param status tokensale status.
   */
  setTokensaleStatus(state: PaymentsState, status: boolean): void {
    state.disableTokenSale = status;
  }
};
