import state from './BlockchainWalletState';
import actions from './BlockchainWalletActions';
import mutations from './BlockchainWalletMutations';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
