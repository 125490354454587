import state from './OnboardingState';
import actions from './OnboardingActions';
import mutations from './OnboardingMutations';
import getters from './OnboardingGetters';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
