import type { WithdrawRequestsState } from './WithdrawRequestsState';
import type { LoadingStatusPayload, WithdrawRequestItem } from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category TokenManagement
 */

export default {
  /**
   * Set loading status.
   * @param state WithdrawRequestsState state.
   * @param payload loading status for some key.
   */
  setLoadingState(
    state: WithdrawRequestsState,
    payload: LoadingStatusPayload
  ): void {
    state.loading[payload.key] = payload.status;
  },

  /**
   * Set list
   * @param state WithdrawRequestsState State.
   * @param data
   */
  setList(state: WithdrawRequestsState, data: WithdrawRequestItem[]): void {
    state.list = [...data];
  },

  /**
   * Set total
   * @param state WithdrawRequestsState State.
   * @param data
   */
  setTotal(state: WithdrawRequestsState, data: number): void {
    state.total = data;
  },

  /**
   * Set pending total
   * @param state WithdrawRequestsState State.
   * @param data
   */
  setPendingTotal(state: WithdrawRequestsState, data: number): void {
    state.pendingTotal = data;
  },

  /**
   * Set item
   * @param state WithdrawRequestsState State.
   * @param data
   */
  setItem(state: WithdrawRequestsState, data: WithdrawRequestItem): void {
    state.item = data;
  }
};
