import { OperatorWallet } from '@/api/schema';

/**
 * The initial state for OperatorWallet.
 * @category  OperatorWallet.
 */
export default {
  operatorWallet: {},
  loading: false
};

/**
 * Describes OperatorWallet state.
 * @category OperatorWallet.
 */
export interface OperatorWalletState {
  operatorWallet: OperatorWallet;
  loading: boolean;
}
