import { ActionContext } from 'vuex';
import { mobileEvent } from '@/api/MobileOperator';
import type { ResponseObject, MobileEventItem } from '@/api/schema';
import type { MobileOperatorState } from './MobileOperatorState';

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category Operator Mobile
 */

interface SettingsForRequest {
  page: number;
  size: number;
  sort: string;
}

export default {
  /**
   * Action used to get mobile events
   * @param vuex Action Context.
   * @param settings settings for request
   */

  getMobileEvents(
    { commit }: ActionContext<MobileOperatorState, unknown>,
    settings: SettingsForRequest
  ): void {
    const { page, size, sort } = settings;
    mobileEvent(page, size, sort)
      .then((data: ResponseObject<MobileEventItem>): void => {
        commit('setMobileEvents', data);
      })
      .catch(() => {
        commit('setMobileEvents', []);
      });
  }
};
