import type { TokensInfoState } from '@/store/modules/tokens-info/TokensInfoState';
import type { TokenInfoData } from '@/api/schema';

export default {
  smartContractAddressBSC(state: TokensInfoState): string {
    const tokenInfoBSC = state.tokenInfo.find(
      (item: TokenInfoData) => item.network === 'BSC'
    );

    if (!tokenInfoBSC) return;

    return tokenInfoBSC.smartContractAddress;
  }
};
