import type { OperatorWalletState } from './OperatorWalletState';
import type { OperatorWallet } from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category OperatorWallet
 */

export default {
  /**
   * Set operator Wallet.
   * @param state OperatorWalletState.
   * @param wallet Operator wallet data.
   */
  setOperatorWallet(state: OperatorWalletState, wallet: OperatorWallet): void {
    state.operatorWallet = wallet;
  },

  /**
   * Set operator Wallet loading state.
   * @param state OperatorWalletState.
   * @param value loading state.
   */
  setLoadingState(state: OperatorWalletState, value: boolean): void {
    state.loading = value;
  }
};
