import { PaymentMethod } from '@/api/schema';

/**
 * The initial state for  Payments.
 * @category   Payments.
 */
export default {
  methods: [],
  disableTokenSale: false,
  loading: {
    methods: true
  }
};

/**
 * Describes  Payments state.
 * @category  Payments.
 */
export interface PaymentsState {
  methods: PaymentMethod[];
  disableTokenSale: boolean;
  loading: {
    methods: boolean;
  };
}
