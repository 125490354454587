import Vue from 'vue';
import Vuex from 'vuex';
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule';
import Auth from './modules/auth/AuthStore';
import OperatorWallet from './modules/operator-wallet/OperatorWalletStore';
import TokensInfo from './modules/tokens-info/TokensInfoStore';
import ClientsInfo from './modules/clients-info/ClientsInfoStore';
import StakingPrograms from './modules/staking-programs/StakingProgramsStore';
import Mining from './modules/mining/MiningStore';
import SuperAdmin from './modules/super-admin/SuperAdminStore';
import BlockchainWallet from './modules/blockchain-wallet/BlockchainWalletStore';
import Onboarding from './modules/onboarding/OnboardingStore';
import Operator from './modules/operator/OperatorStore';
import Dashboard from './modules/dashboard/DashboardStore';
import Payments from './modules/payments/PaymentsStore';
import Promo from './modules/promo/PromoStore';
import FeatureFlags from './modules/feature-flags/FeatureFlagsStore';
import WithdrawRequests from './modules/withdraw-requests/WithdrawRequestsStore';
import OperatorPayments from './modules/operator-payments/OperatorPaymentsStore';
import MobileOperator from './modules/mobile-operator/MobileOperatorStore';
import app from './modules/app/index';

Vue.use(Vuex);
const store = new Vuex.Store<any>({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    appConfig: appConfigStoreModule,
    Auth,
    OperatorWallet,
    TokensInfo,
    ClientsInfo,
    StakingPrograms,
    Mining,
    SuperAdmin,
    BlockchainWallet,
    Dashboard,
    Onboarding,
    Operator,
    Payments,
    Promo,
    FeatureFlags,
    WithdrawRequests,
    OperatorPayments,
    MobileOperator
  }
});
export default store;
