import state from './PromoState';
import actions from './PromoActions';
import mutations from './PromoMutations';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
