import state from './SuperAdminState';
import actions from './SuperAdminActions';
import mutations from './SuperAdminMutations';
import getters from './SuperAdminGetters';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
