import type { OperatorState } from './OperatorState';
import type {
  AccountUser,
  CompanyInfo,
  LoadingStatusPayload,
  TAccountStorage,
  TOperatorEventsList
} from '@/api/schema';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category Operator
 */

export default {
  /**
   * Set loading status.
   * @param state Operator state.
   * @param payload Operator loading status for some key.
   */
  setLoadingState(state: OperatorState, payload: LoadingStatusPayload): void {
    state.loading[payload.key] = payload.status;
  },

  /**
   * Set operator users data.
   * @param state Operator.
   * @param data users data.
   */
  setUsers(state: OperatorState, data: AccountUser[]): void {
    state.users = data;
  },

  /**
   * Set operator default language.
   * @param state Operator.
   * @param language default language.
   */
  setDefaultLanguage(state: OperatorState, language: string): void {
    state.defaultLanguage = language;
  },

  /**
   * Set the list of available languages for the operator widget.
   * @param state Operator.
   * @param languages list of languages.
   */
  setLanguages(state: OperatorState, languages: string[]): void {
    state.languages = languages;
  },

  /**
   * Set operator company info.
   * @param state Operator.
   * @param data languages data.
   */
  setCompanyInfo(state: OperatorState, data: CompanyInfo): void {
    state.companyInfo = data;
  },

  setAccountStorage(state: OperatorState, data: TAccountStorage): void {
    state.storage = { ...state.storage, ...data };
  },

  resetAccountStorage(state: OperatorState): void {
    state.storage = { notification: [] };
  },

  setAccountStorageError(state: OperatorState, hasError = true): void {
    state.storageError = hasError;
  },

  setOperatorEvents(state: OperatorState, data: TOperatorEventsList): void {
    state.operatorEvents = data;
  }
};
