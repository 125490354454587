import { PromoCampaignData, UsersItem } from '@/api/schema';

/**
 * The initial state for Promo Campaigns data.
 * @category  Promo Campaigns.
 */

export const defaultPromoState: PromoState = {
  loading: {
    campaigns: false
  },
  users: [],
  selectedUsers: [],
  campaigns: {
    items: [],
    total: 0
  },
  needUpdate: false,
  selectedCampaign: null,
  dialog: {
    restrictionList: false,
    selectUsers: false,
    participantsList: false,
    campaignForm: false,
    deleteCampaign: false,
    giveReward: false,
    updateConfirmation: false,
    updateStatus: false
  }
};

export default {
  ...defaultPromoState
};

/**
 * Describes Referrals program data state.
 * @category Referrals.
 */
export interface PromoState {
  loading: {
    campaigns: boolean;
  };
  campaigns: {
    items: PromoCampaignData[];
    total: number;
  };
  needUpdate: boolean;
  selectedCampaign: PromoCampaignData | null;
  users: UsersItem[];
  selectedUsers: string[];
  dialog: {
    restrictionList: boolean;
    selectUsers: boolean;
    campaignForm: boolean;
    deleteCampaign: boolean;
    participantsList: boolean;
    giveReward: boolean;
    updateConfirmation: boolean;
    updateStatus: boolean;
  };
}
