import state from './OperatorWalletState';
import actions from './OperatorWalletActions';
import mutations from './OperatorWalletMutations';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
