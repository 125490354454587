import type { ActionContext } from 'vuex';
import { OperatorPaymentsState } from './OperatorPaymentsState';
import type { AxiosError } from 'axios';

import { errorToastMessage } from '@/helpers/errorToastMessage';
import {
  getOperatorBalance,
  getOperatorWallet,
  getOperatorInvoiceHistory
} from '@/api/OperatorPayments';
import { saveOperatorBalance } from '@/api/SuperAdminOperatorPayments';
import type { OperatorBalance, VuetifyTableOptions } from '@/api/schema';

export default {
  /**
   * Get operator balance
   */
  getOperatorBalance({
    commit
  }: ActionContext<OperatorPaymentsState, any>): Promise<unknown> {
    commit('setLoadingState', { key: 'balance', status: true });

    return getOperatorBalance()
      .then((result) => {
        commit('setBalance', result);
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'balance', status: false });
      });
  },

  /**
   * Save operator balance settings
   */
  saveOperatorBalance(
    { commit }: ActionContext<OperatorPaymentsState, unknown>,
    payload: OperatorBalance
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'balance', status: true });

    return saveOperatorBalance(payload)
      .then((result) => {
        commit('setBalance', result);
      })
      .catch((err: AxiosError) => {
        errorToastMessage(err);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'balance', status: false });
      });
  },

  /**
   * Get operator balance
   */
  getOperatorWallet({
    commit
  }: ActionContext<OperatorPaymentsState, unknown>): Promise<unknown> {
    commit('setLoadingState', { key: 'wallet', status: true });

    return getOperatorWallet(true)
      .then((result) => {
        commit('setWallet', result);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'wallet', status: false });
      });
  },

  /**
   *  Set add funds modal state
   */
  setAddFundsModalState(
    { commit }: ActionContext<OperatorPaymentsState, unknown>,
    value: boolean
  ): void {
    commit('setAddFundsModalState', value);
  },

  /**
   * Get operator balance invoice history
   */
  getOperatorInvoiceHistory(
    { commit }: ActionContext<OperatorPaymentsState, any>,
    payload: VuetifyTableOptions
  ): Promise<unknown> {
    commit('setLoadingState', { key: 'invoicesHistory', status: true });

    return getOperatorInvoiceHistory({
      size: payload.itemsPerPage,
      page: payload.page - 1,
      from: payload.from,
      to: payload.to
    })
      .then((result) => {
        commit('setInvoiceHistory', result);
      })
      .finally(() => {
        commit('setLoadingState', { key: 'invoicesHistory', status: false });
      });
  }
};
