import state from './MiningState';
import actions from './MiningActions';
import mutations from './MiningMutations';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
