import type { ActionContext } from 'vuex';
import type { TokensInfoState } from './TokensInfoState';
import {
  getFrozenTokensData,
  getTokenPriceData,
  getTokenInfoData
} from '@/api/TokensInfo';
import type {
  TokenPriceData,
  FrozenTokensData,
  TokenInfoData
} from '@/api/schema';

import stateDefaults from './TokensInfoState';
import { errorToastMessage } from '@/helpers/errorToastMessage';

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category TokenManagement
 */

export default {
  /**
   * Action used to get total tokens frozen data.
   * @param vuex Action Context.
   */
  getFrozenTokensData({
    commit
  }: ActionContext<TokensInfoState, unknown>): void {
    getFrozenTokensData()
      .then((data: FrozenTokensData): void => {
        commit('setFrozenTokensData', data);
      })
      .catch(errorToastMessage);
  },

  /**
   * Action used to get tokens price data.
   * @param vuex Action Context.
   */
  getTokenPriceData({ commit }: ActionContext<TokensInfoState, unknown>): void {
    getTokenPriceData()
      .catch((err) => {
        errorToastMessage(err);
        return stateDefaults.tokenPrice;
      })
      .then((data: TokenPriceData): void => {
        commit('setTokenPriceData', data);
      });
  },

  /**
   * Action used to get tokens price data.
   * @param vuex Action Context.
   */
  getTokenInfoData({ commit }: ActionContext<TokensInfoState, unknown>): void {
    getTokenInfoData()
      .then((data: TokenInfoData[]): void => {
        commit('setTokenInfoData', data);
      })
      .catch(errorToastMessage);
  }
};
