import {
  OperatorWalletForDeposit,
  OperatorBalance,
  OperatorInvoiceHistory
} from '@/api/schema';

/**
 * The initial state for Operator Payments.
 * @category  Operator.
 */

export default {
  balance: {},
  invoicesHistory: {},
  wallet: {},
  addFundsModalState: false,
  loading: {
    balance: false,
    invoicesHistory: false,
    wallet: false
  }
};

/**
 * Describes Operator Payments state.
 * @category Operator Payments.
 */
export interface OperatorPaymentsState {
  balance: OperatorBalance;
  invoicesHistory: OperatorInvoiceHistory;
  wallet: OperatorWalletForDeposit;
  addFundsModalState: boolean;
  loading: {
    balance: boolean;
    invoicesHistory: boolean;
    wallet: boolean;
  };
}
