import { StakingProgram } from '@/api/schema';

/**
 * The initial state for StakingPrograms.
 * @category  StakingPrograms.
 */
export default {
  loading: true,
  programs: [],
  totalInStakingUsers: 0,
  totalUsersLastWeekRatio: 0
};

/**
 * Describes StakingPrograms state.
 * @category StakingPrograms.
 */
export interface StakingProgramsState {
  loading: boolean;
  programs: StakingProgram[];
  totalInStakingUsers: number;
  totalUsersLastWeekRatio: number;
}
