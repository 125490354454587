import {
  getOperatorFeatureFlags,
  adminGetOperatorFeatureFlags
} from '@/api/FeatureFlags';
import type { ActionContext } from 'vuex';
import type { FeatureFlagsState } from './FeatureFlagsState';
import type { FeatureFlagItem } from '@/api/schema';
import { isSuperAdmin } from '@/helpers/superAdminHelper';

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category FeatureFlags.
 */

export default {
  /**
   * Action used to get feature flags data.
   * @param vuex Action Context.
   */
  getOperatorFeatureFlags({
    commit
  }: ActionContext<FeatureFlagsState, unknown>): void {
    commit('setFeatureFlagsLoadingStatus', true);

    const method = isSuperAdmin
      ? adminGetOperatorFeatureFlags
      : getOperatorFeatureFlags;

    method()
      .then((data: FeatureFlagItem[]): void => {
        commit('setFeatureFlagsData', data);
      })
      .catch((): void => {
        commit('setFeatureFlagsData', []);
      })
      .finally((): void => {
        commit('setFeatureFlagsLoadingStatus', false);
      });
  }
};
