import { getPromoCampaigns } from '@/api/PromoCampaign';
import type { ActionContext } from 'vuex';
import type { AxiosError } from 'axios';
import type { PromoState } from './PromoState';
import {
  DialogCampaignData,
  PromoCampaignDataRequest,
  PromoCampaignListResponse
} from '@/api/schema';

/**
 * Actions are used to commit state mutations
 * and can contain asynchronous operations.
 * @category Promo Campaigns.
 */

export default {
  /**
   * Action used to get promo campainds data.
   * @param vuex Action Context.
   * @param searchParams search params for campings.
   */
  getPromoCampaigns(
    { commit }: ActionContext<PromoState, unknown>,
    searchParams: PromoCampaignDataRequest
  ): void {
    commit('setLoadingState', { key: 'campaigns', status: true });
    getPromoCampaigns(searchParams)
      .then((data: PromoCampaignListResponse) => {
        commit('setCampaigns', data);
      })
      .catch((error: AxiosError) => {
        console.log(`Campaigns data loading - `, error.response?.data);
      })
      .finally((): void => {
        commit('setLoadingState', { key: 'campaigns', status: false });
        commit('setUpdateState', false);
      });
  },

  /**
   * Action used to show delete confirmaion dialog.
   * @param vuex Action Context.
   * @param status display status.
   * @param data campaign data.
   */
  showDeleteDialog(
    { commit }: ActionContext<PromoState, unknown>,
    { status, data }: DialogCampaignData
  ): void {
    commit('setDialogStatus', { key: 'deleteCampaign', status });
    commit('setSelectedCampaign', data);
  },

  /**
   * Action used to show games blacklist dialog.
   * @param vuex Action Context.
   * @param status display status.
   * @param data campaign data.
   */
  showGamesBlacklistDialog(
    { commit }: ActionContext<PromoState, unknown>,
    { status, data }: DialogCampaignData
  ): void {
    commit('setDialogStatus', { key: 'gamesBlacklist', status });
    commit('setSelectedCampaign', data);
  },

  /**
   * Action used to show update confirmaion dialog.
   * @param vuex Action Context.
   * @param status display status.
   */
  showUpdateConfirmationDialog(
    { commit }: ActionContext<PromoState, unknown>,
    { status }: DialogCampaignData
  ): void {
    commit('setDialogStatus', { key: 'updateConfirmation', status });
  },

  /**
   * Action used to show update status confirmation dialog.
   */
  showUpdateStatusConfirmationDialog(
    { commit }: ActionContext<PromoState, unknown>,
    { status, data }: DialogCampaignData
  ): void {
    commit('setDialogStatus', { key: 'updateStatus', status });
    commit('setSelectedCampaign', data);
  },

  /**
   * Action used to show canpaign form dialog.
   * @param vuex Action Context.
   * @param status display status.
   * @param data campaign data.
   */
  showCampaignFormDialog(
    { commit }: ActionContext<PromoState, unknown>,
    { status, data }: DialogCampaignData
  ): void {
    commit('setDialogStatus', { key: 'campaignForm', status });
    commit('setSelectedCampaign', data);
  },

  /**
   * Action used to show participants list dialog.
   * @param vuex Action Context.
   * @param status display status.
   * @param data campaign data.
   */

  showParticipantsDialog(
    { commit }: ActionContext<PromoState, unknown>,
    { status, data }: DialogCampaignData
  ): void {
    commit('setDialogStatus', { key: 'participantsList', status });
    commit('setSelectedCampaign', data);
    commit('setUpdateState', !status);
  },

  /**
   * Action used to show restriction user list dialog.
   * @param vuex Action Context.
   * @param status display status.
   * @param data campaign data.
   */

  showRestrictionDialog(
    { commit }: ActionContext<PromoState, unknown>,
    { status, data }: DialogCampaignData
  ): void {
    commit('setDialogStatus', { key: 'restrictionList', status });
    commit('setSelectedCampaign', data);
    commit('setUpdateState', !status);
  },

  /**
   * Action used to show user select dialog.
   * @param vuex Action Context.
   * @param status display status.
   */

  showUserSelectDialog(
    { commit }: ActionContext<PromoState, unknown>,
    status: boolean
  ): void {
    commit('setDialogStatus', { key: 'selectUsers', status });
    commit('setUpdateState', !status);
  },

  /**
   * Action used to show giveReward confirmation dialog.
   * @param vuex Action Context.
   * @param status display status.
   * @param data campaign data.
   */

  showGiveRewardDialog(
    { commit }: ActionContext<PromoState, unknown>,
    { status, data }: DialogCampaignData
  ): void {
    commit('setDialogStatus', { key: 'giveReward', status });
    commit('setSelectedCampaign', data);
    commit('setUpdateState', !status);
  }
};
