import state from './PaymentsState';
import actions from './PaymentsActions';
import mutations from './PaymentsMutations';
import getters from './PaymentsGetters';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
