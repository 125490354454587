import type { FeatureFlagItem } from '@/api/schema';

/**
 * The initial state for feature flags.
 * @category FeatureFlags.
 */
const defaultFeatureFlags = () => {
  const featureFlagNames = [
    'calculator',
    'referral_program',
    'tokensale',
    'most_popular_games',
    'hold_to_earn',
    'custody',
    'partner_mode',
    'withdrawal_requests',
    'promo_management',
    'leaderboard',
    'liquidity_staking_token_usdt',
    'smart_contract_address',
    'casino_income',
    'wallet_auth',
    'historical_game_traffic',
    'new_design',
    '2fa_enabled',
    'language_select',
    'live_streamer',
    'tokensale_private',
    'tokensale_public',
    'hide_logo',
    'split_deposit_dex_transactions',
    'play_to_earn',
    'dashboard_charts',
    'payment_limits',
    'token_burn_out',
    'invoicing',
    'manual_trading_transfer',
    'remove_tokens_non_active_users',
    'daily_cashback_new_users',
    'weekly_cashback_new_users',
    'pancake_V3'
  ];

  const defaultFeatureFlagParams = {
    enabled: false,
    adminVisible: false
  };

  return featureFlagNames.map((name) => ({
    name,
    ...defaultFeatureFlagParams
  }));
};

export default {
  features: [...defaultFeatureFlags()],
  loading: true
};

/**
 * Describes feature management state.
 * @category FeatureFlags.
 */

export interface FeatureFlagsState {
  features: FeatureFlagItem[];
  loading: boolean;
}
