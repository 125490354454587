import type { ClientsInfoState } from './ClientsInfoState';

export default {
  /**
   * Get listed on pancakeswap status.
   * @param state ClientInfo State.
   * @return pancakeswap listed status.
   */

  isListed(state: ClientsInfoState): boolean {
    return !!state.clientsInfo.pancakeSwapUrl;
  }
};
